import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import SearchPage from './components/SearchPage.vue';
import SignInPage from './components/SignInPage.vue';

// Define the routes
const routes = [
  {
    path: '/',
    name: 'SignInPage',
    component: SignInPage
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { requiresAuth: true } // Protect this route
  },
  {
    path: '/search/:id/:name',
    name: 'SearchPage',
    component: SearchPage,
    meta: { requiresAuth: true } // Protect this route as well
  }
];

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

// Navigation guard to check for token
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token'); // Get token from localStorage

  // If the token exists and the route is SignInPage, redirect to HomePage
  if (token && to.name === 'SignInPage') {
    next({ name: 'HomePage' });
  }
  else if (to.matched.some(record => record.meta.requiresAuth)) {
    if(token){
      next()
    }
    else{
      next({ name: 'SignInPage' });
    }
  } else {
    next();
  }
});

export default router;