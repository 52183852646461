<template>
  <section>
    <div class="media" style="">
      <img class="image-media" src="./assets/bva.jpg" />
    </div>
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>


<style>
  .media {
    width: 100%; 
    height: 200px; 
    background-color: #fff100;
  }

  .image-media {
    width: 100%; 
    height: 200px; 
    object-fit: contain;
  }

  /* Tablet styles (768px to 1023px) */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .media {
      height: 250px; /* Adjust height for tablet */
    }

    .image-media {
      height: 250px; /* Adjust height for tablet */
    }
  }

  /* Mobile styles (up to 767px) */
  @media only screen and (max-width: 767px) {
    .media {
      height: 150px; /* Adjust height for mobile */
    }

    .image-media {
      height: 150px; /* Adjust height for mobile */
    }
  }

</style>

