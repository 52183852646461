<template>
  <section>
    <div class="container">
      <div style="margin-top: 20px">
        <div class="row justify-content-center align-items-center">
          <!--<div class="col-md-2 mb-3">
            <router-link :to="{ name: 'SearchPage', params: { id: 128, name: 'Dhanu (ST)' }}">
              <button class="btn btn-primary text-light" style="width: 100%; background-color: #2e3a9e;">
                128 - Dhanu (ST)
              </button>
            </router-link>
          </div>
          <div class="col-md-2 mb-3">
            <router-link :to="{ name: 'SearchPage', params: { id: 129, name: 'Vikramgad (ST)' }}">
              <button class="btn btn-primary text-light" style="width: 100%; background-color: #2e3a9e;">
                129 - Vikramgad (ST)
              </button>
            </router-link>
          </div>
          <div class="col-md-2 mb-3">
            <router-link :to="{ name: 'SearchPage', params: { id: 130, name: 'Palghar (ST)' }}">
              <button class="btn btn-primary text-light" style="width: 100%; background-color: #2e3a9e;">
                130 - Palghar (ST)
              </button>
            </router-link>
          </div>--->
          <div class="col-md-2 mb-3">
            <router-link :to="{ name: 'SearchPage', params: { id: 131, name: 'Boisar (ST)' }}">
              <button class="btn btn-primary text-light" style="width: 100%; background-color: #2e3a9e;">
                131 - Boisar (ST)
              </button>
            </router-link>
          </div>
          <div class="col-md-2 mb-3">
            <router-link :to="{ name: 'SearchPage', params: { id: 132, name: 'Nalasopara' }}">
              <button class="btn btn-primary text-light" style="width: 100%; background-color: #2e3a9e;">
                132 - Nalasopara
              </button>
            </router-link>
          </div>
          <div class="col-md-2 mb-3">
            <router-link :to="{ name: 'SearchPage', params: { id: 133, name: 'Vasai' }}">
              <button class="btn btn-primary text-light" style="width: 100%; background-color: #2e3a9e;">
                133 - Vasai
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div style="margin-top: 24px">
        <p class="text-center"><b>To search for my name in the electoral roll, please select the assembly and enter the first three letters of my first name, middle name, and last name.</b></p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'HomePage'
}
</script>

<style>
  
</style>
