<template>
  <section>
    <div class="container">
      <div class="mb-3 alert alert-solid" style="background-color: #2e3a9e; margin-top: 10px; margin-bottom: 10px;">
        <center>
          <span style="color: #fff">{{constinuency}} - {{label}}</span>
        </center>
      </div>
      <!---<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #fff100">
              <h5 class="modal-title" id="staticBackdropLabel"><b>Voter Details</b></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
            </div>
            <div class="modal-body" v-if="voter_details">
              <p><b>Assembly:</b> {{constinuency}} - {{label}}</p>
              <p><b>First Name:</b> {{voter_details.e_first_name}}</p>
              <p><b>Middle Name:</b> {{voter_details.e_middle_name}}</p>
              <p><b>Last Name:</b> {{voter_details.e_last_name}}</p>
              <p><b>Booth Address:</b> {{voter_details.e_boothaddress || voter_details.l_boothaddress}}</p>
              <p><b>Part Number:</b> {{voter_details.part_no}}</p>
              <p><b>Serial Number:</b> {{voter_details.srno}}</p>
              <p><b>Voter Epic Number:</b> {{voter_details.vcardid}}</p>
              <div class="mt-3 d-flex align-items-center justify-content-center" style="width: 300px;">
                <input type="text" minlength="10" maxlength="10" name="sms_number" class="form-control" placeholder="SMS Number" v-model="sms_number">&nbsp;&nbsp;
                <a 
                  @click="update('sms')" 
                  data-bs-dismiss="modal" 
                  :href="generateSmsLink()" 
                  target="_blank"
                >
                  <button class="btn btn-primary" :disabled="loading" style="background-color: #2e3a9e">
                    Send
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>--->
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #fff100">
              <h5 class="modal-title" id="staticBackdropLabel"><b>Voter Details</b></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
            </div>
            <div class="modal-body" v-if="voter_details">
              <p><b>Assembly: </b> {{constinuency}} - {{label}}</p>
              <p><b>First Name: </b> {{voter_details.e_first_name}}</p>
              <p><b>Middle Name: </b> {{voter_details.e_middle_name}}</p>
              <p><b>Last Name: </b> {{voter_details.e_last_name}}</p>
              <p><b>Booth Address: </b> {{voter_details.e_boothaddress || voter_details.l_boothaddress}}</p>
              <p><b>Part Number: </b> {{voter_details.part_no}}</p>
              <p><b>Serial Number: </b> {{voter_details.srno}}</p>
              <p><b>Voter Epic Number: </b> {{voter_details.vcardid}}</p>
              <div class="mt-3 d-flex align-items-center justify-content-center" style="width: 300px;">
                <input type="text" minlength="10" maxlength="10" name="whatsapp_number" class="form-control" placeholder="Whatsapp Number" v-model="whatsapp_number">&nbsp;&nbsp;
                <a 
                  @click="update('whatsapp')" 
                  data-bs-dismiss="modal" 
                  :href="generateWhatsappLink()" 
                  target="_blank"
                >
                  <button class="btn btn-primary" :disabled="loading" style="background-color: #2e3a9e">
                    Send
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="submit">
        <div class="row d-flex flex-row align-items-center justify-content-center">
          <div class="col-md-12">
            <p class="text-center text-danger" v-if="errMsg != null || errMsg != ''">{{errMsg}}</p>
            <div class="row">
              <div class="col-md-3 mb-3">
                <label><b>First Name</b></label>
                <input type="text" class="form-control" name="first_name" placeholder="First Name" minlength="3" v-model="first_name">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Middle Name</b></label>
                <input type="text" class="form-control" name="middle_name" placeholder="Middle Name" minlength="3" v-model="middle_name">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Last Name</b></label>
                <input type="text" class="form-control" name="last_name" placeholder="Last Name" minlength="3" v-model="last_name">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Voter Epic Number</b></label>
                <input type="text" class="form-control" name="voter_epic_number" placeholder="Voter Epic Number" minlength="3" v-model="voter_epic_number">
              </div>
              <div class="col-md-12 mb-3">
                <div class="d-flex flex-row">
                  <button class="btn btn-primary" :disabled="loading" style="background-color: #2e3a9e">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    Search
                  </button>
                  <button type="button" @click="logout()" class="btn btn-primary" :disabled="loading" style="margin-left: 10px; background-color: #2e3a9e">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    Logout
                  </button>
                  <button type="button" @click="clear()" class="btn btn-primary" :disabled="loading" style="margin-left: 10px; background-color: #2e3a9e">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    Clear
                  </button>
                  <router-link :to="{name: 'HomePage'}">
                    <button class="btn btn-primary" style="margin-left: 10px; background-color: #2e3a9e">
                      Back
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr v-if="dataset.length" />
      <div style="margin-top: 10px;">
        <div class="row">
          <div class=" col-md-4 d-flex align-items-stretch" v-for="data, index in dataset" :key="{index}">
            <div class="card card-body mb-3 d-flex flex-column mx-auto" style="border-color: #fff100;">
              <div class="d-flex flex-column align-items-center justify-content-between" style="height: 420px;">
                <div>
                  <p><b>Assembly: </b> {{constinuency}} - {{label}}</p>
                  <p><b>First Name: </b>{{data.l_first_name}} ({{data.e_first_name}}) </p>
                  <p><b>Middle Name: </b>{{data.l_middle_name}} ({{data.e_middle_name}})</p>
                  <p><b>Last Name: </b>{{data.l_last_name}} ({{data.e_last_name}})</p>
                  <p><b>Booth Address: </b> {{data.e_boothaddress || data.l_boothaddress}}</p>
                  <p><b>Part Number: </b> {{data.part_no}}</p>
                  <p><b>Serial Number: </b> {{data.srno}}</p>
                  <p><b>Voter Epic Number: </b> {{data.vcardid}}</p>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-between" style="width: 100%">
                  <div @click="voter(data)" class="d-flex flex-row align-items-center" style="text-decoration: none; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <img src="../assets/whatsapp.png" style="width: 16px; height: 16px;">
                    <span style="margin-left: 10px; color: #4caf50;"><b>Slip Via Whatsapp</b></span>
                  </div>
                  <!---<div @click="voter(data)" class="d-flex flex-row align-items-center" style="text-decoration: none; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
                    <img src="../assets/sms.png" style="width: 16px; height: 16px;">
                    <span style="margin-left: 10px; color: #4caf50;"><b>Slip Via SMS</b></span>
                  </div>--->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import axios from 'axios';
import crypto from 'crypto'; // Make sure your build system supports this

export default {
  name: 'SearchPage',
  data() {
    return {
      constinuency: this.$route.params.id,
      label: this.$route.params.name,
      first_name: "",
      middle_name: "",
      last_name: "",
      voter_epic_number: "",
      loading: false,
      errMsg: '',
      dataset: [],
      voter_details: null,
      whatsapp_number: null,
      sms_number: null
    };
  },
  methods: {
    submit () {
      this.loading = true
      this.errMsg = ''
      if(this.first_name == "" && this.middle_name == "" && this.last_name == "" && this.voter_epic_number == ""){
        this.errMsg = 'Invalid Search. Please search by either first name, middle name, last name, voter epic number.'
        this.loading = false
        return true
      }
      if(this.first_name){
        if(this.first_name.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      if(this.middle_name){
        if(this.middle_name.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      if(this.last_name){
        if(this.last_name.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      if(this.voter_epic_number){
        if(this.voter_epic_number.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      const data = {
        first_name: this.first_name?this.first_name.trim():"",
        last_name: this.last_name?this.last_name.trim():"",
        middle_name: this.middle_name?this.middle_name.trim():"",
        voter_epic_number: this.voter_epic_number?this.voter_epic_number.trim():"",
        constituency: this.constinuency?this.constinuency.trim():""
      }
      const token = localStorage.getItem('token')
      axios({
        method: 'POST',
        data: data,
        url: '/api/v1/search',
        headers: {
          authkey: token
        }
      })
      .then((response) => {
        if(response.data.errCode == 1){
          this.errMsg = response.data.errMsg
          this.loading = false
        }
        else{
          const key = "74ce70de4186b559eb8c621e0d2f02c73453d63fef0fd7ded907b7b2a273dad5";
          const iv = "cb10ec48787215bca255b3b0f258fdf6";
          const commonKey = Buffer.from(key, 'hex');
          const commonIv = Buffer.from(iv, 'hex');

          // Create decipher
          let decipher = crypto.createDecipheriv('aes-256-cbc', commonKey, commonIv);

          // Decrypt data
          let decrypted = decipher.update(Buffer.from(response.data, 'hex'));
          decrypted = Buffer.concat([decrypted, decipher.final()]);

          // Return the decrypted data as a string
          const _data = decrypted.toString();
          this.dataset = JSON.parse(_data)
          this.loading = false
        }
      })
      .catch(() => {
        this.errMsg = "Something went wrong. Please try again"
        this.loading = false
      })
    },
    voter (item) {
      this.voter_details = item
      this.whatsapp_number = null
      this.sms_number = null
    },
    generateWhatsappLink() {

      const constituencyMap = {
        131: 'बोईसर',
        132: 'नालासोपारा',
        133: 'वसई'
      };
      
      const candidateMap = {
        131: 'राजेश रघुनाथ पाटील',
        132: 'क्षितिज हितेंद्र ठाकूर',
        133: 'हितेंद्र विष्णू ठाकूर'
      };

      const candidateURL = {
        131: 'https://rajeshpatil.bahujanvikasaghadi.com',
        132: 'https://kshitijthakur.bahujanvikasaghadi.com/',
        133: 'https://hitendrathakur.bahujanvikasaghadi.com'
      }
      
      const constituencyName = constituencyMap[parseInt(this.constinuency)] || '';
      const candidateName = candidateMap[parseInt(this.constinuency)] || '';
      const constituencyUrl = candidateURL[parseInt(this.constinuency)] || 'https://voters.bahujanvikasaghadi.com/'
      
      const message = `*Voters Slip*\n\n` +
        `*नमस्कार, ${this.voter_details.l_first_name}*\n` +
        `*विधानसभा - ${constituencyName}*\n` +
        `*उमेदवार : ${candidateName}*\n` +
        `*पार्टी : बहुजन विकास आघाडी*\n` +
        `*निशाणी : शिट्टी*\n` +
        `-----------------------------\n` +
        `Assembly: *${this.constinuency}-${constituencyName} (${this.constinuency}-${this.label})*\n` +
        `First Name: *${this.voter_details.l_first_name} (${this.voter_details.e_first_name})*\n` +
        `Middle Name: *${this.voter_details.l_middle_name} (${this.voter_details.e_middle_name})*\n` +
        `Last Name: *${this.voter_details.l_last_name} (${this.voter_details.e_last_name})*\n` +
        `Booth Address: *${this.voter_details.l_boothaddress || this.voter_details.e_boothaddress}*\n` +
        `Yadi Number: *${this.voter_details.part_no}*\n` +
        `Serial Number: *${this.voter_details.srno}*\n` +
        `Voter Epic Number: *${this.voter_details.vcardid}*\n\n` +
        `*आपला विकास बहुजन विकास*\n\n` +
        `आमदार *${candidateName} - बहुजन विकास आघाडी* उमेदवार\nनिशाणी: *शिट्टी*\n` +
        `${constituencyUrl} ${constituencyName} विधानसभा आपल्या ओळखीतील लोकांचे नाव शोधा आणि त्याचे तपशील Whatsapp किंवा sms द्वारे लोकांना पाठवा.`;
      
      // URL encode the message for WhatsApp
      const encodedMessage = encodeURIComponent(message);
      
      // Create the full WhatsApp URL
      return `https://wa.me/+91${this.whatsapp_number}?text=${encodedMessage}`;
    },
    generateSmsLink() {
      const constituencyMap = {
        131: 'बोईसर',
        132: 'नालासोपारा',
        133: 'वसई'
      };
      
      const candidateMap = {
        131: 'राजेश रघुनाथ पाटील',
        132: 'क्षितिज हितेंद्र ठाकूर',
        133: 'हितेंद्र विष्णू ठाकूर'
      };
      
      const constituencyName = constituencyMap[parseInt(this.constinuency)] || '';
      const candidateName = candidateMap[parseInt(this.constinuency)] || '';

      const message = `Voters Slip\n` +
        `नमस्कार, ${this.voter_details.l_first_name}\n` +
        `विधानसभा - ${constituencyName}\n` +
        `उमेदवार : ${candidateName}\n` +
        `पार्टी : बहुजन विकास आघाडी\n` +
        `निशाणी : शिट्टी\n` +
        `-----------------------------\n` +
        `Assembly: ${this.constinuency}-${constituencyName} (${this.constinuency}-${this.label})\n` +
        `First Name: ${this.voter_details.e_first_name}\n` +
        `Middle Name: ${this.voter_details.e_middle_name}\n` +
        `Last Name: ${this.voter_details.e_last_name}\n` +
        `Booth Address: ${this.voter_details.e_boothaddress || this.voter_details.l_boothaddress}\n` +
        `Yadi Number: ${this.voter_details.part_no}\n` +
        `Serial Number: ${this.voter_details.srno}\n` +
        `Voter Epic Number: ${this.voter_details.vcardid}\n` +
        `आपला विकास बहुजन विकास`;

      // URL encode the message for SMS
      const encodedMessage = encodeURIComponent(message);
      
      // Create the SMS link
      return `sms:+91${this.sms_number}?body=${encodedMessage}`;
    },
    update (type) {
      this.voter_details['whatsapp_number'] = this.whatsapp_number || this.sms_number
      const token = localStorage.getItem('token')
      axios({
        method: 'POST',
        url: '/api/v1/update',
        data: this.voter_details,
        params: {
          type: type
        },
        headers: {
          authkey: token
        }
      })
      .then((response) => {
        if(response.data.errCode == -1){
          console.log(response.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    logout () {
      localStorage.clear()
      this.$router.push({"name": "SignInPage"})
    },
    clear() {
      this.first_name = ""
      this.middle_name = ""
      this.last_name = ""
      this.voter_epic_number = ""
    }
  }
};
</script>




<style>
</style>
